import { Card, Row } from 'antd'
import { FunctionComponent, useEffect } from 'react'
import styled from 'styled-components'
import { colors } from 'constants/colors'
import { useTranslation } from 'react-i18next'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { BicycleAppListView } from 'api/main'
import { getBicyclesRequestAsync } from 'store/bicycle/get/asyncActions'
import { Link } from 'react-router-dom'
import { BicycleRequest, selectBicycles } from 'store/bicycle/get'

interface YouMightLikeInterface {
  id?: number
}

export const YouMightLike: FunctionComponent<YouMightLikeInterface> = ({ id }) => {
  const { t } = useTranslation<string>()
  const { data } = useAppSelector(selectBicycles)
  const bicycles: BicycleAppListView[] = data
  const dispatch = useAppDispatch()

  useEffect(() => {
    const request: BicycleRequest = { page: 1, size: 5, bicycleTypeIds: [id] }
    dispatch(getBicyclesRequestAsync(request))
  }, [dispatch, id])

  return (
    <Container>
      <Row justify={'center'}>
        <HighlightTitle>{t('youMightAlsoLike')}</HighlightTitle>
      </Row>
      <RowWithGap justify="start" wrap={false}>
        {bicycles.map((bike: BicycleAppListView) => (
          <Link key={bike.id} to={'/bicycle/' + bike.id}>
            <CardMargin
              hoverable
              cover={
                <ImageHolder>
                  <Img alt={bike.description} src={bike.medias[0]?.url} />
                </ImageHolder>
              }
            >
              <Card.Meta title={<Title>{bike.brand.name}</Title>} description={<Price>€ {bike.price}</Price>} />
            </CardMargin>
          </Link>
        ))}
      </RowWithGap>
    </Container>
  )
}
const RowWithGap = styled(Row)`
  gap: 12px;
  overflow-x: auto;
  overflow-y: hidden;
  padding: 12px;
`
const Container = styled.div`
  margin: 20px auto;
  width: auto;
  overflow: hidden;
`
const Title = styled(Row)`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #${colors.GRAY_TEXT};
`
const Price = styled(Row)`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  display: flex;
  align-items: center;
  color: ${colors.BLACK_TR};
`
const CardMargin = styled(Card)`
  max-width: 300px;

  & .ant-row {
    white-space: normal;
  }

  & .ant-card-cover {
    height: 75%;
    margin: 0;
  }

  & .ant-card-cover > div {
    height: 100%;
  }

  & .ant-card-cover > div > img {
    max-height: 100%;
  }

  width: 238px;
  height: 279px;
  padding: 10px;
`
const ImageHolder = styled.div`
  max-width: 300px;
  max-height: 350px;
  background: ${colors.WHITE};
  display: flex;
  justify-content: center;
  align-items: center;
`
const HighlightTitle = styled(Row)`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 45px;
  display: flex;
  color: ${colors.BLACK};
  margin-bottom: 20px;
`
const Img = styled.img`
  max-width: 100%;
`
