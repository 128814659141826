import React, { ReactElement, useEffect, useState } from 'react'
import 'bootstrap/dist/css/bootstrap-grid.css'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { InnerPageBrand } from 'Components/Brands/InnerPage/InnerPage'
import TopPanelImage from 'assets/images/TopPanel.jpg'
import { colors } from 'constants/colors'
import { Input } from 'antd'
import { Highlights } from './Highlights/Highlights'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { getAppBannerRequestAsync } from 'store/banners/getAll/asyncActions'
import { BannerPosition } from 'api/main'
import { getContentRequestAsync } from '../../store/contents/get/asyncActions'
import { selectAppContent } from '../../store/contents/get'
import { HelmetWrapper } from '../UiKit/HelmetWrapper/HelmetWrapper'

function Home(): ReactElement {
  const { t } = useTranslation<string>()
  const [query, setQuery] = useState('')
  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(getAppBannerRequestAsync(BannerPosition.HOME))
  }, [dispatch])

  useEffect(() => {
    dispatch(getContentRequestAsync('HOME_HEADING'))
  }, [dispatch])

  const { data } = useAppSelector(selectAppContent)

  return (
    <Container>
      <HelmetWrapper parentData={{ titleKey: 'home' }} />
      <TopPanel>
        <TopPanelH1>{t('chooseYourFavorite')}</TopPanelH1>
        <TopPanelSubHeader>{t('slogan')}</TopPanelSubHeader>
        <SearchBox
          placeholder={t('SearchPlaceHolder')}
          allowClear
          enterButton={t('search')}
          size="large"
          onSearch={setQuery}
        />
      </TopPanel>
      <InnerContainer>
        <Highlights content={data} />
        <InnerPageBrand searchQuery={query} />
      </InnerContainer>
    </Container>
  )
}

export default Home
const InnerContainer = styled.div`
  max-width: 1200px;
  margin: 20px auto;
`
const Container = styled.div`
  background-color: ${colors.SELL_BACKGROUND};
`
const TopPanel = styled.div`
  background-image: url(${TopPanelImage}), linear-gradient(${colors.TOP_PANEL_OVERLAY}, ${colors.TOP_PANEL_OVERLAY});
  background-position: center;
  background-blend-mode: overlay;
  height: 440px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 0 -10px;
  padding: 20px;
`
const TopPanelH1 = styled.h1`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: bold;
  line-height: 72px;
  font-size: 30px;
  display: flex;
  align-items: center;
  color: ${colors.WHITE};
  @media (min-width: 768px) {
    max-width: 70%;
    font-size: 48px;
  }
  @media (max-width: 600px) {
    line-height: 60px;
  }
  @media (max-width: 370px) {
    line-height: 50px;
  }
  @media (max-width: 340px) {
    line-height: 40px;
  }
  text-align: center;
`
const TopPanelSubHeader = styled.p`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 27px;
  display: flex;
  align-items: center;
  text-align: center;
  color: ${colors.WHITE};
`
const SearchBox = styled(Input.Search)`
  max-width: 700px;
`
